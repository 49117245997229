import React, {useState} from "react";
import {Button, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {isAbleToDelete} from "../../ui-util/general-variable";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";

const MenuList = () => {
    const dispatch = useDispatch();
    const {mainMenuList, mainMenuLoading} = useSelector(state => state.menuReducer)
    const {searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            dataIndex: 'name', title: 'Name',
            render: name => <div style={Styles.fontArmyGrayBold}>{name}</div>
        }, {
            dataIndex: 'slug', title: 'Slug',
            render: (slug) => <div style={{color: Colors.darkGrey}}>{slug}</div>
        }, {
            title: 'Action',
            render: (record) => {
                return (
                    <>
                        <Button onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: true, modalTitle: 'Update main menu'}
                            })
                            dispatch({type: MENU_REDUCER, payload: {mainMenuDetail: record}})
                        }} style={Styles.buttonUpdateDelete}>
                            <div style={Styles.fontArmyGrayBold}>
                                Update
                            </div>
                        </Button>
                        {
                            isAbleToDelete(record.slug) &&
                            <Button onClick={() => {
                                dispatch({
                                    type: MODAl_REDUCER,
                                    payload: {modalVisibility: true, modalTitle: 'Delete main menu'}
                                })
                                dispatch({type: MENU_REDUCER, payload: {mainMenuDetail: record}})
                            }} style={{borderRadius: 12}}>
                                <div style={Styles.fontArmyGrayBold}>Delete</div>
                            </Button>
                        }
                    </>
                )
            }
        },
    ])

    const filteredData = () => {
        let dataFiltered = []
        mainMenuList.forEach(item => {
            if(item.name.toLowerCase().includes(searchKey.toLowerCase())){
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }

    return (
        <Table
            columns={columns}
            dataSource={filteredData()}
            rowKey={record => record.id}
            loading={mainMenuLoading}
        />
    )
}

export default MenuList
