import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import {Button, Form, Input, List, Select} from "antd";
import {createMenuApi, updateMenuApi} from "../../api/menu.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";

const {Option} = Select;

const MenuForm = () => {
    const formRef = React.createRef();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const {menuDetail, mainMenuList, menuReload} = useSelector(state => state.menuReducer)
    const {modalTitle} = useSelector(state => state.modalReducer)

    const [menuForm] = useState([
        {
            name: 'mainmenuId',
            label: 'Main menu',
            type: 'select',
            required: true
        }, {
            name: 'name',
            label: 'Name',
            type: 'input',
            required: true
        }, {
            name: 'url',
            label: 'Url',
            type: 'input',
            required: false
        }, {
            name: 'slug',
            label: 'Slug',
            type: 'input',
            required: false
        }
    ])

    useEffect(() => {
        let formCurrent = formRef.current

        if (!!menuDetail.name) {
            formCurrent.setFieldsValue(menuDetail)
        }

        return () => {
            formCurrent.setFieldsValue({name: '', url: '', slug: '', mainmenuId: ''})
        }

    }, [menuDetail])


    const onFinish = (values) => {
        setLoading(true)
        if (modalTitle.includes('Create')) {
            createMenu(values)
        } else {
            updateMenu(values)
        }
    }

    const createMenu = (values) => {
        createMenuApi(values)
            .then(response => {
                setLoading(false)
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {menuReload: !menuReload}})
                alert('Sub menu berhasil dibuat!')
            })
            .catch(err => {
                setLoading(false)
                alert(err.messages[0].error)
            })
    }

    const updateMenu = (values) => {
        let requestBody = {
            mainmenuId: values.mainmenuId,
            name: values.name,
            url: values.url
        }
        updateMenuApi(menuDetail?.id, requestBody)
            .then(response => {
                setLoading(false)
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {menuReload: !menuReload}})
                alert('Sub menu berhasil diupdate!')
            })
            .catch(err => {
                console.log('Error updateMenuApi', err.response)
                setLoading(false)
                alert(err.messages[0].error)
            })
    }

    return (
        <Form ref={formRef} layout="vertical" name="control-hooks" onFinish={onFinish} labelCol={{span: 5}}>
            <List
                dataSource={menuForm}
                renderItem={(item, index) => {
                    switch (item.type) {
                        case 'select':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select style={{width: 400}}>
                                        {
                                            mainMenuList.map((item, index) => (
                                                <Option value={item.id} key={index.toString()}>
                                                    <div style={{color: Colors.armyGray}}>
                                                        {item.name}
                                                    </div>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input style={Styles.input} placeholder={item.placeholder}
                                           disabled={modalTitle.includes('Update') && item.name === 'slug'}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={Styles.buttonCreate}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default MenuForm
