import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
const token = localStorage.getItem('TOKEN_AUTH');

const createRoleApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/role/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response);
        });
    }));
}

const getRoleListApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/role/list`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const deleteRoleApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/role/delete/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const updateRoleApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/role/update/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const getRoleDetailByIdApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/role/detail/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

export {
    createRoleApi,
    getRoleListApi,
    deleteRoleApi,
    updateRoleApi,
    getRoleDetailByIdApi
}
