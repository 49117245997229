import {combineReducers} from 'redux';
import {modalReducer} from "./modal.reducer";
import {menuReducer} from "./menu.reducer";
import {analyticsReducer} from "./analytics.reducer";

export default combineReducers({
    modalReducer,
    menuReducer,
    analyticsReducer
})
