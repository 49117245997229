import React from "react";
import {Colors} from "../ui-util/colors";
import {Input} from "antd";
import {useDispatch} from "react-redux";
import {ANALYTICS_REDUCER} from "../reducers/analytics.reducer";

const {Search} = Input;

const SearchComponent = () => {
    const dispatch = useDispatch();
    return (
        <div style={{width: '20%'}}>
            <Search
                placeholder="input search text"
                allowClear
                onSearch={value => dispatch({type: ANALYTICS_REDUCER, payload: {searchKey: value}})}
                style={{color: Colors.armyGray}}
            />
        </div>
    )
}

export default SearchComponent
