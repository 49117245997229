import React, {useEffect} from "react";
import {Button, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import CreateMenuFormComponent from "./create-menu-form.component";
import {deleteMainMenuApi, mainMenuListApi} from "../../api/main-menu.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import MenuList from "./menu-list.component";
import ModalComponent from "../../components/modal.component";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";
import {FLUSH_SEARCH_KEY} from "../../reducers/analytics.reducer";

const MainMenuSetting = () => {
    const dispatch = useDispatch();
    const {modalTitle} = useSelector(state => state.modalReducer)
    const {mainMenuDetail, mainMenuReload} = useSelector(state => state.menuReducer)

    useEffect(() => {
        dispatch({type: MENU_REDUCER, payload: {mainMenuLoading: true}})
        mainMenuListApi()
            .then(response => {
                dispatch({type: MENU_REDUCER, payload: {mainMenuList: response, mainMenuLoading: false}})
            })
            .catch(err => {
                console.log(err.response)
                dispatch({type: MENU_REDUCER, payload: {mainMenuLoading: false}})
            })

        return () => {
            dispatch({type: FLUSH_SEARCH_KEY})
        }
    }, [mainMenuReload])

    const deleteMainMenu = () => {
        deleteMainMenuApi(mainMenuDetail?.id)
            .then(response => {
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {mainMenuReload: !mainMenuReload}})
                alert('Main menu berhasil di hapus!')
            })
            .catch(err => {
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                console.log(err)
                alert('Mainmenu has sub menu, please delete sub menu before deleting main menu!')
            })
    }

    return (
        <>
            <Row justify={"space-between"} style={{marginTop: 20}}>
                <Button style={Styles.button}
                        onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: true, modalTitle: 'Create Main Menu', mainMenuDetail: {}}
                            })
                            dispatch({
                                type: MENU_REDUCER,
                                payload: {mainMenuDetail: {}}
                            })
                        }}>
                    Create main menu</Button>
                <SearchComponent/>
            </Row>
            <MenuList/>
            <ModalComponent
                content={
                    modalTitle.includes('Delete') ?
                        <div style={{color: Colors.armyGray}}>Apakah anda yakin ingin menghapus main menu ini?</div> :
                        <CreateMenuFormComponent/>
                }
                footer={!modalTitle.includes('Delete') ? null :
                    <div>
                        <Button onClick={() => deleteMainMenu()} style={Styles.buttonOk}>Ya</Button>
                        <Button onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: false}
                            })
                        }} style={Styles.buttonCancel}>Tidak</Button>
                    </div>
                }
            />
        </>
    )
}

export default MainMenuSetting
