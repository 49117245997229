import React, {useEffect, useState} from "react";
import {Button, Row, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import RoleList from "./role-list.component";
import {deleteRoleApi, getRoleListApi} from "../../api/role.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import ModalComponent from "../../components/modal.component";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";
import {FLUSH_SEARCH_KEY} from "../../reducers/analytics.reducer";

const {Option} = Select

const RoleSetting = () => {
    const dispatch = useDispatch();
    const [roleId, setRoleId] = useState(null)
    const {roleDetail, roleReload, roleList} = useSelector(state => state.menuReducer)
    const {modalVisibility} = useSelector(state => state.modalReducer)

    useEffect(() => {
        getRoleListApi()
            .then(response => {
                dispatch({type: MENU_REDUCER, payload: {roleList: response}})
            })
            .catch(err => console.log(err))

        return () => {
            dispatch({type: FLUSH_SEARCH_KEY})
        }
    }, [roleReload])

    useEffect(() => {
        return () => {
            setRoleId(null)
        }

    }, [modalVisibility])


    const deleteRole = () => {
        if (roleId) {
            deleteRoleApi(roleDetail?.id, {otherRoleForUser: roleId})
                .then(response => {
                    dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                    dispatch({type: MENU_REDUCER, payload: {roleReload: !roleReload}})
                    alert('Role berhasil dihapus!')
                })
                .catch(err => console.log(err.response))
        } else {
            alert('Masukkan role id pengganti!')
        }
    }

    return (
        <>
            <Row justify={"space-between"} style={{marginTop: 20}}>
                <Link to={`/create-role`}>
                    <Button style={Styles.button}>Create role</Button>
                </Link>
                <SearchComponent/>
            </Row>
            <RoleList/>
            <ModalComponent
                content={<>
                    <Select style={{minWidth: 400}} value={roleId} onChange={(value) => setRoleId(value)}>
                        {
                            roleList.map((item, index) => (
                                <Option value={item.id} key={index.toString()}>
                                    <div style={{color: Colors.armyGray}}>
                                        {item.name}
                                    </div>
                                </Option>
                            ))
                        }
                    </Select>
                    <div style={{marginTop: 20, color: Colors.armyGray}}>Mohon masukkan role pengganti untuk user yang
                        sudah menggunakan role yang akan anda hapus
                    </div>
                </>}
                footer={
                    <div>
                        <Button onClick={() => deleteRole()} style={Styles.buttonOk}>Ya</Button>
                        <Button onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: false}
                            })
                            setRoleId(null)
                        }} style={Styles.buttonCancel}>Tidak</Button>
                    </div>
                }
            />
        </>
    )
}

export default RoleSetting
