import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const createEmbedUrlApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/embed-url/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    }));
}

const getEmbedUrlListApi = () => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/embed-url/list`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const updateEmbedUrlApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/embed-url/update/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const getEmbedUrlApi = (pathName) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/embed-url/detail/${pathName}`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const deleteEmbedUrlApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/embed-url/delete/${id}`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    createEmbedUrlApi,
    getEmbedUrlListApi,
    updateEmbedUrlApi,
    getEmbedUrlApi,
    deleteEmbedUrlApi
}
