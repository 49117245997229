import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, List} from "antd";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {getAllSideBarListApi} from "../../api/main-menu.api";
import {useDispatch, useSelector} from "react-redux";
import {createRoleApi, getRoleDetailByIdApi, updateRoleApi} from "../../api/role.api";
import {useParams} from "react-router-dom";
import {Styles} from "../../ui-util/styles";
import HeaderPageComponent from "../../components/header-page.component";

const CreateRolePage = () => {
    const formRef = React.createRef();
    const {id} = useParams();
    const dispatch = useDispatch();
    const [mainMenus, setMainMenus] = useState([])
    const [menus, setMenus] = useState([])
    const [embedUrls, setEmbedUrls] = useState([])
    const {allSideBarList, roleDetail} = useSelector(state => state.menuReducer)
    const [roleForm] = useState([
        {
            name: 'name',
            label: 'Name',
            type: 'input',
            required: true
        }, {
            name: 'menuCapabilities',
            label: 'Capabilities',
            type: 'checkbox',
            required: false
        }
    ])

    useEffect(() => {
        let formCurrent = formRef.current
        const initialData = async () => {
            dispatch({type: MENU_REDUCER, payload: {roleDetail: {}}})
            if (!!id) {
                const response = await getRoleDetailByIdApi(id)
                dispatch({type: MENU_REDUCER, payload: {roleDetail: response}})
                formCurrent.setFieldsValue({name: response.name})
                setMainMenus(response.capabilityIds.mainmenus)
                setMenus(response.capabilityIds.menus)
                setEmbedUrls(response.capabilityIds.embedUrls)
            }

            getAllSideBarListApi()
                .then(response => {
                    dispatch({type: MENU_REDUCER, payload: {allSideBarList: response}})
                })
                .catch(err => {
                    console.log(err.response)
                    dispatch({type: MENU_REDUCER, payload: {menuLoading: false}})
                })
        }
        initialData(formCurrent)
        return () => {
            formCurrent.setFieldsValue({name: ''})
        }
    }, [id])

    const onFinish = (values) => {
        if (mainMenus.length > 0) {
            if (embedUrls.length > 0 && menus.length === 0) {
                alert('Silahkan pilih parent menu dan main menu!')
            } else if (menus.length > 0 && mainMenus.length === 0) {
                alert('Silahkan pilih parent menu dan main menu!')
            } else if (menus.length === 0) {
                alert('Silahkan pilih parent menu dan main menu!')
            } else {
                let requestBody = {
                    name: values.name,
                    menuCapabilities: {
                        mainmenus: mainMenus,
                        menus,
                        embedUrls
                    }
                }
                if (!!id) {
                    updateRole(requestBody)
                } else {
                    createRole(requestBody)
                }
            }
        } else {
            alert('Silahkan pilih parent menu dan main menu!')
        }
    }

    const createRole = (values) => {
        createRoleApi(values)
            .then(response => {
                window.location.href = '/role-setting'
                alert('Role berhasil dibuat!')
            })
            .catch(err => console.log(err.response))
    }

    const updateRole = (values) => {
        updateRoleApi(id, values)
            .then(response => {
                alert('Role berhasil diubah!')
                window.location.href = '/role-setting'
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const removeItem = (array, indexItem) => {
        const index = array.indexOf(indexItem);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array
    }

    return (
        <>
            <HeaderPageComponent title={ !!id ? 'Update Role' : 'Create Role' }/>
            <Form ref={formRef} name="control-hooks" onFinish={onFinish} labelCol={{span: 2}}>
                <List
                    dataSource={roleForm}
                    renderItem={(item, index) => {
                        switch (item.type) {
                            case 'checkbox':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <List
                                            dataSource={allSideBarList}
                                            renderItem={mainMenu => {
                                                return (
                                                    <>
                                                        <Checkbox
                                                            value={mainMenu.id}
                                                                  defaultChecked={!!roleDetail?.name && roleDetail?.capabilities.includes(mainMenu.slug)}
                                                                  onChange={(a) => {
                                                                      if (a.target.checked) {
                                                                          mainMenus.push(a.target.value)
                                                                      } else {
                                                                          let newMainMenus = removeItem(mainMenus, a.target.value)
                                                                          setMainMenus(newMainMenus)
                                                                      }
                                                                  }}>
                                                            {mainMenu.name}
                                                        </Checkbox>
                                                        <br/>
                                                        {
                                                            mainMenu.menus.length > 0 &&
                                                            <List
                                                                style={{marginTop: 10, marginBottom: 10}}
                                                                dataSource={mainMenu.menus}
                                                                renderItem={menu => {
                                                                return(
                                                                    <div style={{marginLeft: 20}}>
                                                                        <Checkbox value={menu.id}
                                                                                  defaultChecked={!!roleDetail?.name && !!roleDetail.capabilities.includes(menu.slug)}
                                                                                  onChange={(a) => {
                                                                                      if (a.target.checked) {
                                                                                          menus.push(a.target.value)
                                                                                      } else {
                                                                                          let newMenus = removeItem(embedUrls, a.target.value)
                                                                                          setMenus(newMenus)
                                                                                      }
                                                                                  }}>
                                                                            {menu.name}
                                                                        </Checkbox>
                                                                        <br/>
                                                                        {
                                                                            menu.pages.length > 0 &&
                                                                            <List
                                                                                style={{
                                                                                    marginTop: 10,
                                                                                    marginBottom: 10
                                                                                }}
                                                                                dataSource={menu.pages}
                                                                                renderItem={page => (
                                                                                    <div style={{marginLeft: 20}}>
                                                                                        <Checkbox value={page.id}
                                                                                                  defaultChecked={!!roleDetail?.name && !!roleDetail.capabilities.includes(page.slug)}
                                                                                                  onChange={(a) => {
                                                                                                      if (a.target.checked) {
                                                                                                          embedUrls.push(a.target.value)
                                                                                                      } else {
                                                                                                          let newEmbedUrls = removeItem(embedUrls, a.target.value)
                                                                                                          setEmbedUrls(newEmbedUrls)
                                                                                                      }
                                                                                                  }}>
                                                                                            {page.name}
                                                                                        </Checkbox>
                                                                                        <br/>
                                                                                    </div>
                                                                                )}
                                                                            />
                                                                        }
                                                                    </div>
                                                                )}}
                                                            />
                                                        }
                                                    </>
                                                )
                                            }}
                                        />
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Input style={Styles.input} placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    }}
                />
                <Form.Item
                    wrapperCol={{
                        offset: 2,
                    }}
                >
                    <Button type="primary" htmlType="submit" style={Styles.buttonCreate}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default CreateRolePage
