const INITIAL_STATE = {
    mainMenuList: [],
    mainMenuLoading: false,
    mainMenuDetail: {},
    mainMenuReload: false,
    menuList: [],
    menuLoading: false,
    menuDetail: {},
    menuReload: false,
    roleList: [],
    roleLoading: false,
    roleDetail: {},
    roleReload: false,
    subSubMenuReload: false,
    allSideBarList: [],
    embedUrlList: []
}

export const MENU_REDUCER = 'MENU_REDUCER';

export const menuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENU_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}
