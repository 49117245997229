import React from 'react'
import {Layout} from 'antd';
import SideBar from "./side-bar.component";

const {Content} = Layout;

const Dashboard = (props) => {
    const pathRaw = window.location.pathname.split('/')
    const pathName = pathRaw[1]
    const token = localStorage.getItem('TOKEN_AUTH');
    const {content} = props

    return (
        <Layout style={{
            backgroundColor: !!token ? '#eff2f5' : 'white'
        }}>
            {(!!token || pathName !== 'maintenance') && <SideBar/>}
            <Content
                style={{
                    margin: 20,
                    padding: 20,
                    backgroundColor: !!token ? 'white' : 'transparent',
                }}>
                {content}
            </Content>
        </Layout>
    )
}

export default Dashboard
