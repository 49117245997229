import {useEffect, useState} from "react";
import {getMenuWithSequenceApi, updateSequenceMenuApi} from "../../api/menu.api";
import IcUpDown from '../../images/ic-chev-down.svg'
import {deepClone} from "../../ui-util/general-variable";

const ArrangeMenuSetting = () => {
    const [menu, setMenu] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState({menu: null, subMenu: null});

    useEffect(() => {
        getMenu()
    }, []);

    const getMenu = () => {
        getMenuWithSequenceApi()
            .then(response => {
                setMenu(response)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const onClickMenu = (slug, value, i) => {
        switch (slug) {
            case 'menu':
                setSelectedIndex({menu: i, subMenu: null})
                return
            case 'subMenu':
                setSelectedIndex({...selectedIndex, subMenu: i})
        }
    }

    const onClickUp = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[index - 1] = item
        menuCopy[index] = menu[index - 1]
        setMenu(menuCopy)
    }

    const onClickDown = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[index + 1] = item
        menuCopy[index] = menu[index + 1]
        setMenu(menuCopy)
    }

    const onClickUpSubMenu = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[selectedIndex?.menu].menus[index - 1] = item
        menuCopy[selectedIndex?.menu].menus[index] = menu[selectedIndex?.menu].menus[index - 1]
        setMenu(menuCopy)
    }

    const onClickDownSubMenu = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[selectedIndex?.menu].menus[index + 1] = item
        menuCopy[selectedIndex?.menu].menus[index] = menu[selectedIndex?.menu].menus[index + 1]
        setMenu(menuCopy)
    }

    const onClickUpSubSubMenu = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index - 1] = item
        menuCopy[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index] = menu[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index - 1]
        setMenu(menuCopy)
    }

    const onClickDownSubSubMenu = (index, item) => {
        let menuCopy = deepClone(menu)
        menuCopy[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index + 1] = item
        menuCopy[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index] = menu[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages[index + 1]
        setMenu(menuCopy)
    }

    const updateMenu = () => {
        const arrangeSequence = menu.map((menu, menuIndex) => {
            menu.menus.map((subMenu, subMenuIndex) => {
                subMenu.sequence = subMenuIndex
                if(subMenu.pages.length > 0) {
                    subMenu.pages.map((subSubMenu, subSubMenuIndex) => {
                        subSubMenu.sequence = subSubMenuIndex
                    })
                }
            })
            menu.sequence = menuIndex
            return menu
        })

        updateSequenceMenuApi({mainmenus: arrangeSequence})
            .then(() => alert('Urutan menu berhasil di update'))
    }

    return (
        <div>
            <div className={'grid grid-cols-3 gap-8 p-4'}>
                <div className={'border rounded-t-lg bg-primary-10 border-primary-20'}>
                    <div
                        className={'h-12 flex justify-center items-center bg-primary-100 text-white rounded-t-lg font-semibold'}>
                        Menu
                    </div>
                    <div className={'space-y-2 p-2'}>
                        {
                            menu.map((item, index) => {
                                return (
                                    <div key={index}
                                         className={`flex justify-between p-4 ${selectedIndex.menu === index && 'bg-white border rounded-md'}`}>
                                        <p className={'text-gray-600 cursor-pointer'}
                                           onClick={() => onClickMenu('menu', item, index)}>{item.name}</p>
                                        <div className={'flex gap-4'}>
                                            <button onClick={() => onClickUp(index, item)}
                                                    className={`${index === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={index === 0}>
                                                <img src={IcUpDown} alt={'IcUp'} className={`rotate-180 h-4 w-4`}/>
                                            </button>
                                            <button onClick={() => onClickDown(index, item)}
                                                    className={`${index === (menu.length - 1) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={index === (menu.length - 1)}>
                                                <img src={IcUpDown} alt={'IcDown'} className={'h-4 w-4'}/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'border rounded-t-lg bg-primary-10 border-primary-20'}>
                    <div
                        className={'h-12 flex justify-center items-center bg-primary-100 text-white rounded-t-lg font-semibold'}>
                        Sub Menu
                    </div>
                    <div className={'space-y-2 p-2'}>
                        {
                            selectedIndex?.menu !== null ?
                                menu[selectedIndex?.menu].menus.map((item, idx) => (
                                    <div key={idx}
                                         className={`flex justify-between p-4 ${selectedIndex?.subMenu === idx && 'bg-white border rounded-md'}`}>
                                        <p className={'text-gray-600 cursor-pointer'}
                                           onClick={() => onClickMenu('subMenu', item, idx)}>{item.name}</p>
                                        <div className={'flex gap-4'}>
                                            <button onClick={() => onClickUpSubMenu(idx, item)}
                                                    className={`${idx === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={idx === 0}>
                                                <img src={IcUpDown} alt={'IcUp'} className={'rotate-180 h-4 w-4'}/>
                                            </button>
                                            <button onClick={() => onClickDownSubMenu(idx, item)}
                                                    className={`${(idx + 1) === menu[selectedIndex?.menu].menus?.length ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={(idx + 1) === menu[selectedIndex?.menu].menus?.length}>
                                                <img src={IcUpDown} alt={'IcDown'} className={'h-4 w-4'}/>
                                            </button>
                                        </div>
                                    </div>
                                )) : <p>Empty</p>
                        }
                    </div>
                </div>
                <div className={'border rounded-t-lg bg-primary-10 border-primary-20'}>
                    <div
                        className={'h-12 flex justify-center items-center bg-primary-100 text-white rounded-t-lg font-semibold'}>
                        Sub Sub Menu
                    </div>
                    <div className={'space-y-2 p-2'}>
                        {
                            selectedIndex?.subMenu !== null ?
                                menu[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages.map((item, idx) => (
                                    <div key={idx} className={`flex justify-between p-4`}>
                                        <p className={'text-gray-600'}>{item.name}</p>
                                        <div className={'flex gap-4'}>
                                            <button onClick={() => onClickUpSubSubMenu(idx, item)}
                                                    className={`${idx === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={idx === 0}>
                                                <img src={IcUpDown} alt={'IcUp'} className={'rotate-180 h-4 w-4'}/>
                                            </button>
                                            <button onClick={() => onClickDownSubSubMenu(idx, item)}
                                                    className={`${(idx + 1) === menu[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages?.length ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={(idx + 1) === menu[selectedIndex?.menu].menus[selectedIndex?.subMenu].pages?.length}>
                                                <img src={IcUpDown} alt={'IcDown'} className={'h-4 w-4'}/>
                                            </button>
                                        </div>
                                    </div>
                                )) : <p>Empty</p>
                        }
                    </div>
                </div>
            </div>
            <div className={'flex flex-row items-center justify-center mt-10'}>
                <button className={'px-20 py-2 bg-primary-100 text-white rounded-md'} onClick={updateMenu}>Update</button>
            </div>
        </div>
    )
}

export default ArrangeMenuSetting
