import React, {useEffect, useState} from "react";
import {Button, Form, Input, List, Select} from "antd";
import {createEmbedUrlApi, updateEmbedUrlApi} from "../../api/embed-url";
import {useSelector} from "react-redux";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";

const {TextArea} = Input;
const {Option} = Select;

const CreateLinkComponent = (props) => {
    const formRef = React.createRef()
    const [loading, setLoading] = useState(false)
    const {linkDetail, modalTitle, onCancelModal} = props
    const {menuList} = useSelector(state => state.menuReducer)
    const [userForm] = useState([
        {
            label: 'Sub Menu',
            name: 'menuId',
            required: true,
            messageAlert: 'Please input sub menu!',
            type: 'select'
        },
        {
            label: 'Name',
            name: 'name',
            required: true,
            messageAlert: 'Please input link name!',
            placeholder: 'Cth: DC_A',
            type: 'input'
        }, {
            label: 'Url',
            name: 'url',
            required: true,
            messageAlert: 'Please input url!',
            placeholder: 'Cth: https://app.powerbi.com/',
            type: 'input'
        }, {
            label: 'Slug',
            name: 'slug',
            required: false,
            messageAlert: 'Please input slug!',
            type: 'input'
        }
    ])

    useEffect(() => {
        let formCurrent = formRef.current
        if (!!linkDetail.name) {
            formCurrent.setFieldsValue(linkDetail)
        }

        return () => {
            formCurrent.setFieldsValue({menuId: '', name: '', url: '', slug: ''})
        }

    }, [linkDetail])

    const onFinish = (values) => {
        setLoading(true)
        if (modalTitle.includes('Create')) {
            createEmbedUrl(values)
        } else {
            updateEmbedUrl(values)
        }
    }

    const createEmbedUrl = async (values) => {
        try {
            await createEmbedUrlApi(values)
            setLoading(false)
            alert('Sub sub menu berhasil dibuat!!')
            onCancelModal()
            window.location.reload()
        } catch (e) {
            setLoading(false)
            alert(e.messages[0].error)
            console.log('Error createEmbedUrlApi', e)
        }
    }

    const updateEmbedUrl = async (values) => {
        try {
            await updateEmbedUrlApi(linkDetail?.id, values)
            setLoading(false)
            alert('Sub sub menu berhasil di update!!')
            onCancelModal()
            window.location.reload()
        } catch (e) {
            console.log('Error createEmbedUrlApi', e)
        }
    }

    return (
        <Form ref={formRef} layout="vertical" name="control-hooks" onFinish={onFinish}>
            <List
                dataSource={userForm}
                renderItem={(item, index) => {
                    switch (item.type) {
                        case 'select':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select style={{width: 400}}>
                                        {
                                            menuList.map((i, index) => (
                                                <Option value={i.id} key={index.toString()}>
                                                    <div style={{color: Colors.armyGray}}>
                                                        {i.name}
                                                    </div>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    {
                                        item.name === 'url' ?
                                            <TextArea rows={4} style={Styles.textarea}/>
                                            :
                                            <Input style={Styles.input} placeholder={item.placeholder}
                                                   disabled={modalTitle.includes('Update') && item.name === 'slug'}/>}
                                </Form.Item>
                            )
                    }
                }}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={Styles.buttonCreate}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CreateLinkComponent
