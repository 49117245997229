import React, {useEffect, useState} from "react";
import {Button, Form, Input, List} from "antd";
import {createMainMenuApi, updateMainMenuApi} from "../../api/main-menu.api";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import {useDispatch, useSelector} from "react-redux";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {Styles} from "../../ui-util/styles";

const CreateMenuFormComponent = () => {
    const formRef = React.createRef();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const {mainMenuDetail, mainMenuReload} = useSelector(state => state.menuReducer)
    const {modalTitle} = useSelector(state => state.modalReducer)

    const [mainMenuForm] = useState([
        {
            name: 'name',
            label: 'Name',
            required: true
        }, {
            name: 'slug',
            label: 'Slug',
            required: false
        }
    ])

    useEffect(() => {
        let formCurrent = formRef.current
        if (!!mainMenuDetail.name) {
            formCurrent.setFieldsValue(mainMenuDetail)
        }

        return () => {
            formCurrent.setFieldsValue({name: '', slug: ''})
        }
    }, [mainMenuDetail])

    const onFinish = (values) => {
        setLoading(true)
        if (modalTitle.includes('Create')) {
            createMainMenu(values)
        } else {
            updateMainMenu(values)
        }
    }

    const createMainMenu = (values) => {
        createMainMenuApi(values)
            .then(response => {
                setLoading(false)
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {mainMenuReload: !mainMenuReload}})
                alert('Main menu berhasil dibuat!')
            })
            .catch(err => {
                setLoading(false)
                alert(err.messages[0].error)
            })
    }

    const updateMainMenu = (values) => {
        let requestBody = {
            name: values.name
        }
        updateMainMenuApi(mainMenuDetail.id, requestBody)
            .then(response => {
                setLoading(false)
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {mainMenuReload: !mainMenuReload}})
                alert('Main menu berhasil diubah!')
            })
            .catch(err => {
                setLoading(false)
                alert(err.messages[0].error)
            })
    }

    return (
        <Form ref={formRef} layout="vertical" name="control-hooks" onFinish={onFinish} labelCol={{span: 3}}
              wrapperCol={{span: 10}}>
            <List
                dataSource={mainMenuForm}
                renderItem={(item, index) => (
                    <Form.Item
                        key={index.toString()}
                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                        name={item.name}
                        rules={[{
                            required: item.required,
                            message: item.messageAlert,
                        }]}
                    >
                        <Input style={Styles.input} placeholder={item.placeholder}
                               disabled={modalTitle.includes('Update') && item.name === 'slug'}/>
                    </Form.Item>
                )}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={Styles.buttonCreate}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CreateMenuFormComponent
