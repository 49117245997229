import React, {useState, useEffect} from "react";
import {Button, Form, Input, List, Select} from "antd";
import {createUserApi, updateUserDetailApi} from "../../api/auth.api";
import {getRoleListApi} from "../../api/role.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {useDispatch, useSelector} from "react-redux";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";

const {Option} = Select

const CreateUserFormComponent = (props) => {
    const formRef = React.createRef()
    const [loading, setLoading] = useState(false)
    const {userDetail} = props
    const dispatch = useDispatch();
    const {roleList} = useSelector(state => state.menuReducer)
    const [userForm] = useState([
        {
            label: 'Nama',
            name: 'name',
            required: true,
            messageAlert: 'Please input name!',
            placeholder: 'Cth: Agnes Monica',
            type: 'input'
        }, {
            label: 'Email',
            name: 'email',
            required: true,
            messageAlert: 'Please input email!',
            placeholder: 'Cth: agnesmonica@gmail.com',
            type: 'input'
        }, {
            label: 'Role',
            name: 'roleId',
            required: true,
            messageAlert: 'Please input role!',
            type: 'select'
        }
    ])

    useEffect(() => {
        let formCurrent = formRef.current

        if (!!userDetail) {
            formCurrent.setFieldsValue(userDetail)
        }

        return () => {
            formCurrent.setFieldsValue({name: '', email: '', roleId: null})
        }
    }, [userDetail])

    useEffect(() => {
        getRoleListApi()
            .then(response => {
                dispatch({type: MENU_REDUCER, payload: {roleList: response}})
            })
            .catch(err => console.log(err))
    }, [])

    const onFinish = (values) => {
        setLoading(true)
        if (props.action === 'create') {
            createUser(values)
        } else {
            updateUser(values)
        }
    }

    const createUser = async (values) => {
        try {
            await createUserApi(values)
            setLoading(false)
            alert('User Berhasil dibuat')
            window.location.reload()
        } catch (e) {
            console.log(e)
            alert(e.messages[0].error)
            setLoading(false)
        }
    }

    const updateUser = async (values) => {
        try {
            await updateUserDetailApi(userDetail.id, values)
            setLoading(false)
            alert('User Berhasil di update')
            window.location.reload()
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    return (
        <Form ref={formRef} layout="vertical" name="control-hooks" onFinish={onFinish}>
            <List
                dataSource={userForm}
                renderItem={(item, index) => {
                    switch (item.type) {
                        case 'select':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select
                                        style={{width: 400}}
                                    >
                                        {
                                            roleList.map((item, index) => (
                                                <Option value={item.id} key={index.toString()}>
                                                    <div style={{color: Colors.armyGray}}>
                                                        {item.name}
                                                    </div>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input style={Styles.input} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }
                }
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={Styles.buttonCreate}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CreateUserFormComponent
