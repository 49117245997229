import moment from "moment";

const INITIAL_STATE = {
    startDate: moment(),
    endDate: moment(),
    reload: false,
    searchKey: '',
}

export const ANALYTICS_REDUCER = 'ANALYTICS_REDUCER';
export const FLUSH_DATE_ANALYTICS = 'FLUSH_DATE_ANALYTICS';
export const FLUSH_SEARCH_KEY = 'FLUSH_SEARCH_KEY';

export const analyticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ANALYTICS_REDUCER:
            return {...state, ...action.payload}
        case FLUSH_DATE_ANALYTICS:
            return {...state, startDate: moment(), endDate: moment(), searchKey: ''}
        case FLUSH_SEARCH_KEY:
            return {...state, searchKey: ''}
        default:
            return state
    }
}
