import React, {useEffect, useState} from "react";
import {Row, Table} from "antd";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import {getAnalyticPageApi} from "../../api/analytics.api";
import SearchComponent from "../../components/search.component";
import {useSelector} from "react-redux";
import DatePickerComponent from "../../components/date-picker.component";
import ExportAnalyticPageComponent from "../../components/export-analytic-page.component";

const AnalyticPage = () => {
    const [pageList, setPageList] = useState([])
    const {startDate, endDate, searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            title: 'Page', dataIndex: 'page', key: 'page',
            render: page => <div style={Styles.fontArmyGrayBold}>{page}</div>
        }, {
            title: 'Count', dataIndex: 'count', key: 'count',
            render: (count) => <div style={{color: Colors.armyGray}}>{count}</div>
        }
    ])

    useEffect(() => {
        getAnalyticsData()

    }, [startDate, endDate])

    const getAnalyticsData = () => {
        let requestBody = {
            startDate,
            endDate
        }

        getAnalyticPageApi(requestBody)
            .then(result => {
                setPageList(result)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const filteredData = () => {
        let dataFiltered = []
        pageList.forEach(item => {
            if (item.page.toLowerCase().includes(searchKey.toLowerCase())) {
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }


    return (
        <div>
            <Row style={{marginBottom: 20}} justify={"space-between"}>
                <SearchComponent/>
                <ExportAnalyticPageComponent dataList={filteredData()}/>
                <DatePickerComponent />
            </Row>
            <Table
                columnWidth={100}
                className="components-table-demo-nested"
                columns={columns}
                dataSource={filteredData()}
                rowKey={(item, index) => index}
            />
        </div>
    )
}

export default AnalyticPage
