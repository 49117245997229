import React from "react";
import {Colors} from "../ui-util/colors";
import {Row} from "antd";

const HeaderPageComponent = (props) => {
    const {title} = props
    return (
        <Row align={'middle'}
             style={{
                 width: '100%',
                 marginLeft: 20,
                 marginBottom: 30,
                 height: 75
             }}>
            <div style={{
                fontFamily: 'Belleza-Regular',
                fontSize: 32,
                color: Colors.armyGray,
            }}>{title}</div>
        </Row>
    )
}

export default HeaderPageComponent
