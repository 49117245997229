import React, {useEffect, useState} from "react";
import {Button, Form, Input, List, Select} from "antd";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import {useDispatch, useSelector} from "react-redux";
import {getRoleListApi} from "../../api/role.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {sendEmailApi} from "../../api/email.api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const {Option} = Select

const BlastEmailPage = () => {
    const formRef = React.createRef()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const {roleList} = useSelector(state => state.menuReducer)
    const blastEmailForm = [
        {
            label: 'Role',
            name: 'role_ids',
            required: true,
            messageAlert: 'Please input role!',
            type: 'select'
        }, {
            label: 'Subject',
            name: 'subject',
            required: true,
            messageAlert: 'Please input email subject!',
            placeholder: 'Cth: November Report',
            type: 'input'
        }, {
            label: 'Email Body',
            name: 'body',
            required: true,
            messageAlert: 'Please input email body!',
            placeholder: 'Cth: November report are out, check em out!',
            type: 'textarea'
        },
    ]
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ],
    }
    const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link']

    useEffect(() => {
        getRoleListApi()
            .then(response => {
                dispatch({type: MENU_REDUCER, payload: {roleList: response}})
            })
            .catch(err => console.log(err))
    }, [])

    const onFinish = (values) => {
        setLoading(true)
        sendEmailApi(values)
            .then(response => {
                setLoading(false)
                alert('Email berhasil dikirim')
                window.location.reload()
            })
            .catch(() => {
                setLoading(false)
                alert('Terjadi kesalahan')
            })
    }

    return (
        <div style={{margin: 30}}>
            <Form ref={formRef} layout="vertical" name="control-hooks" onFinish={onFinish}>
                <List
                    dataSource={blastEmailForm}
                    renderItem={(item, index) => {
                        switch (item.type) {
                            case 'select':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            size={'middle'}
                                            placeholder="Please select"
                                            style={{width: 400}}
                                        >
                                        {
                                            roleList.map((item, index) => (
                                                <Option value={item.id} key={index.toString()}>
                                                    <div style={{color: Colors.armyGray}}>
                                                        {item.name}
                                                    </div>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                            </Form.Item>
                            )
                            case 'textarea':
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <ReactQuill
                                            theme="snow"
                                            placeholder={item?.placeholder}
                                            modules={modules}
                                            formats={formats}
                                            style={{width: 400, height: 100, marginBottom: 30}}
                                            className={"text-forged-steel"}
                                        />
                                    </Form.Item>
                                )
                            default:
                                return (
                                    <Form.Item
                                        key={index.toString()}
                                        label={<div style={Styles.fontDarkGreenBold}>{item.label}</div>}
                                        name={item.name}
                                        rules={[{
                                            required: item.required,
                                            message: item.messageAlert,
                                        }]}
                                    >
                                        <Input style={Styles.input} placeholder={item.placeholder}/>
                                    </Form.Item>
                                )
                        }
                    }
                    }
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={Styles.buttonCreate}>
                        Send Email
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default BlastEmailPage
