import React, {useEffect} from "react";
import {Button, Row} from "antd";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import MenuList from "./menu-list.component";
import ModalComponent from "../../components/modal.component";
import {useDispatch, useSelector} from "react-redux";
import {mainMenuListApi} from "../../api/main-menu.api";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {deleteMenuApi, getMenuListApi} from "../../api/menu.api";
import MenuForm from "./menu-form.component";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";
import {FLUSH_SEARCH_KEY} from "../../reducers/analytics.reducer";

const MenuSetting = () => {
    const dispatch = useDispatch();
    const {modalTitle} = useSelector(state => state.modalReducer)
    const {menuDetail, menuReload} = useSelector(state => state.menuReducer)

    useEffect(() => {
        dispatch({type: MENU_REDUCER, payload: {mainMenuLoading: true}})
        Promise.all([getMenuListApi(), mainMenuListApi()])
            .then(response => {
                dispatch({
                    type: MENU_REDUCER,
                    payload: {
                        menuList: response[0],
                        menuLoading: false,
                        mainMenuList: response[1]
                    }
                })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({type: MENU_REDUCER, payload: {menuLoading: false}})
            })

        return () => {
            dispatch({type: FLUSH_SEARCH_KEY})
        }
    }, [menuReload])

    const deleteMenu = () => {
        deleteMenuApi(menuDetail?.id)
            .then(response => {
                alert('Sub menu berhasil di hapus!')
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                dispatch({type: MENU_REDUCER, payload: {menuReload: !menuReload}})
            })
            .catch(err => {
                console.log('err', err)
                dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})
                alert('Sub Menu has sub sub menu, please delete sub sub menu before deleting sub menu!')
            })
    }

    return (
        <>
            <Row justify={"space-between"} style={{marginTop: 20}}>
                <Button style={Styles.button}
                        onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: true, modalTitle: 'Create Sub Menu'}
                            })
                            dispatch({type: MENU_REDUCER, payload: {menuDetail: {}}})
                        }}>
                    Create Sub Menu</Button>
                <SearchComponent/>
            </Row>
            <MenuList/>
            <ModalComponent
                content={
                    modalTitle.includes('Delete') ?
                        <div style={{color: Colors.armyGray}}>Apakah anda yakin ingin menghapus sub menu ini?</div> :
                        <MenuForm/>
                }
                footer={!modalTitle.includes('Delete') ? null :
                    <div>
                        <Button onClick={() => deleteMenu()} style={Styles.buttonOk}>Ya</Button>
                        <Button onClick={() => {
                            dispatch({
                                type: MODAl_REDUCER,
                                payload: {modalVisibility: false}
                            })
                        }} style={Styles.buttonCancel}>Tidak</Button>
                    </div>
                }
            />
        </>
    )
}

export default MenuSetting
