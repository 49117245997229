import React, {useRef} from "react";
import {Button} from "antd";
import {formatDateToReadable} from "../ui-util/general-variable";
import {useSelector} from "react-redux";
import {useDownloadExcel} from "react-export-table-to-excel";

const ExportAnalyticPageComponent = (props) => {
    const tableRef = useRef(null);
    const {dataList} = props
    const {startDate, endDate} = useSelector(state => state.analyticsReducer)

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: formatDateToReadable(startDate) === formatDateToReadable(endDate) ? `Analytics Page ${formatDateToReadable(startDate)}` : `Analytics Page ${formatDateToReadable(startDate)} - ${formatDateToReadable(endDate)}`,
        sheet: 'Analytics Page'
    })

    return (
        <>
            <Button disabled={dataList.length < 1} onClick={onDownload}>Export</Button>

            <table ref={tableRef} style={{display: 'none'}}>
                <tr>
                    <th>Nama Page</th>
                    <th>Count</th>
                </tr>
                {dataList.length > 0 && dataList.map((item, i) => (
                    <tr key={i}>
                        <td>{item.page}</td>
                        <td>{item.count}</td>
                    </tr>
                ))}
            </table>
        </>
    )
}

export default ExportAnalyticPageComponent
