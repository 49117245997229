import {Base64} from 'js-base64';
import moment from "moment";

const cloudFunctionBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const isUserRole = (userRole) => {
    switch (userRole) {
        case '99fedb09f0f5da90e577784e5f9fdc23':
        case 'da7c1b2519415d312411f058c3c72e86':
        case 'c97e79e14549c9f528427e36c90ae782':
            return true
        default:
            return false
    }
}

const clientId = () => {
    return '264358888609-3jp9ssc9p083nfd5g87d9lhjgtpmufat.apps.googleusercontent.com'
}

const stringToBase64 = (stringToEncode) => {
    return Base64.encode(stringToEncode);
}

const isAbleToDelete = (slug) => {
    switch (slug){
        case 'user-setting':
        case 'menu-setting':
        case 'admin-setting':
        case 'role-setting':
        case 'main-menu-setting':
        case 'sub-menu-setting':
        case 'sub-sub-menu-setting':
            return false
        default:
            return true
    }
}

const formatDateToReadable = (date) => {
    return moment(date).format("DD-MM-YYYY")
}

const deepClone = (object) => {
    return JSON.parse(JSON.stringify(object))
}

export {
    isUserRole,
    clientId,
    cloudFunctionBaseUrl,
    stringToBase64,
    isAbleToDelete,
    formatDateToReadable,
    deepClone
}
