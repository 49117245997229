import React, {useEffect, useState} from "react";
import {getEmbedUrlApi} from "../../api/embed-url";
import {getMenuDetailBySlugApi} from "../../api/menu.api";

const PowerBiPage = () => {
    const [url, setUrl] = useState('')
    const pathRaw = window.location.pathname.split('/')

    useEffect(() => {
        if(pathRaw[1] === 'menu'){
            getMenuDetailUrl()
        } else {
            getEmbedUrl()
        }

    }, [pathRaw])

    const getEmbedUrl = async () => {
        try {
            const result = await getEmbedUrlApi(pathRaw[3])
            setUrl(result.url)
        } catch (e) {
            console.log('Error getEmbedUrlApi', e)
        }
    }

    const getMenuDetailUrl = async () => {
        try {
            const result = await getMenuDetailBySlugApi(pathRaw[3])
            setUrl(result.url)
        } catch (e) {
            console.log('Error getEmbedUrlApi', e)
        }
    }


    return (
        <div className="container-iframe">
            <iframe
                className="responsive-iframe"
                src={url}
                frameBorder="0" style={{overflow: 'hidden'}}
                width="100%"></iframe>
        </div>
    )
}

export default PowerBiPage
