import React, {useRef} from "react";
import {Button} from "antd";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../ui-util/general-variable";
import {useDownloadExcel} from "react-export-table-to-excel";

const ExportAnalyticUserComponent = (props) => {
    const tableRef = useRef(null);
    const {dataList} = props
    const {startDate, endDate} = useSelector(state => state.analyticsReducer)

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: formatDateToReadable(startDate) === formatDateToReadable(endDate) ? `Analytics User ${formatDateToReadable(startDate)}` : `Analytics User ${formatDateToReadable(startDate)} - ${formatDateToReadable(endDate)}`,
        sheet: 'Analytics User'
    })

    return (
        <>
            <Button disabled={dataList.length < 1} onClick={onDownload}>Export</Button>
            <table ref={tableRef} style={{display: 'none'}}>
                <tr>
                    <th>Nama</th>
                    <th>Email</th>
                    <th>Total Page View</th>
                    <th>Page</th>
                    <th>Tanggal Akses</th>
                </tr>
                {dataList.length > 0 && dataList.map((item, i) => (
                    <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.totalPage}</td>
                        <td>{item.page}</td>
                        <td>{item.accessTime}</td>
                    </tr>
                ))}
            </table>
        </>
    )
}

export default ExportAnalyticUserComponent
