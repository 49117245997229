import React from "react";
import {MODAl_REDUCER} from "../reducers/modal.reducer";
import {Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Styles} from "../ui-util/styles";

const ModalComponent = (props) => {
    const dispatch = useDispatch();
    const {modalVisibility, modalTitle} = useSelector(state => state.modalReducer)
    const {content, footer} = props

    return (
        <Modal
            visible={modalVisibility}
            title={<div style={Styles.fontDarkGreenBold}>{modalTitle}</div>}
            onCancel={() => dispatch({type: MODAl_REDUCER, payload: {modalVisibility: false}})}
            footer={footer}
        >
            {content}
        </Modal>
    )
}

export default ModalComponent
