import {Colors} from "./colors";

export const Styles = {
    button: {
        width: 200,
        height: 40,
        backgroundColor: Colors.armyGray,
        borderWidth: 0,
        borderRadius: 12,
        marginBottom: 20,
        color: 'white'
    }, fontArmyGrayBold: {
        color: Colors.armyGray,
        fontWeight: 'bold'
    }, fontDarkGreenBold: {
        color: Colors.armyGray,
        fontWeight: 'bold'
    }, buttonUpdateDelete: {
        marginRight: 10,
        borderRadius: 12
    }, buttonCreate: {
        height: 40,
        width: 150,
        backgroundColor: Colors.armyGray,
        borderRadius: 12,
        borderWidth: 0,
        marginTop: 10
    }, input: {
        width: 400,
        height: 40,
        borderRadius: 12,
        color: Colors.armyGray
    }, textarea: {
        width: 400,
        borderRadius: 12,
        color: Colors.armyGray
    }, buttonOk: {
        height: 40,
        width: 100,
        backgroundColor: Colors.armyGray,
        borderRadius: 12,
        borderWidth: 0,
        color: 'white'
    }, buttonCancel: {
        height: 40,
        width: 100,
        borderRadius: 12,
        borderColor: Colors.armyGray,
        color: Colors.armyGray
    }
}
