export const Colors = {
    darkslategray: '#2E4159',
    slategray: '#51718C',
    cadetblue: '#698FB6',
    skyblue: '#91B7D9',
    gainsboro: '#E4E4E4',
    armyGray: '#062d3d',
    softArmy: '#f2f7ef',
    snow: '#f9faf8',
    indianRed: '#b26161',
    darkGrey: '#707070',
    whiteSmoke: '#f5f5f5',
    lightgray: '#c8d1c7',
    darkGreen: '#486a41',
    palevioletred: '#d17f7f',
    seashell: '#ffefef',
    hexGray: '#d6d6d6',
    darkSlateGray: '#333333',
    logoColor: '#a5b4a3',
    darkSeaGreen: '#116584'
}
