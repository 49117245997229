import React, {useState} from "react";
import {Button, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {Styles} from "../../ui-util/styles";

const RoleList = () => {
    const dispatch = useDispatch();
    const {roleList, roleLoading} = useSelector(state => state.menuReducer)
    const {searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            dataIndex: 'name', title: 'Name',
            render: name => <div style={Styles.fontArmyGrayBold}>{name}</div>
        }, {
            title: 'Action',
            render: (record) => {
                return (
                    <>
                        <Button onClick={() => window.location.href = `/update-role/${record.id}`} style={Styles.buttonUpdateDelete}>
                            <div style={Styles.fontArmyGrayBold}>Update</div>
                        </Button>
                        <Button onClick={() => {
                            dispatch({type: MODAl_REDUCER, payload: {modalVisibility: true, modalTitle: 'Delete role'}})
                            dispatch({type: MENU_REDUCER, payload: {roleDetail: record}})
                        }} style={{borderRadius: 12}}>
                            <div style={Styles.fontArmyGrayBold}>Delete</div>
                        </Button>
                    </>
                )
            }
        }
    ])

    const filteredData = () => {
        let dataFiltered = []
        roleList.forEach(item => {
            if(item.name.toLowerCase().includes(searchKey.toLowerCase())){
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }

    return (
        <Table
            columns={columns}
            dataSource={filteredData()}
            rowKey={record => record.id}
            loading={roleLoading}
        />
    )
}

export default RoleList
