import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
const token = localStorage.getItem('TOKEN_AUTH');

const sendEmailApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/email/send`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response);
        });
    }));
}

export {
    sendEmailApi
}
