import React from "react";
import MaintenanceImage from "../../images/maintenance-5.png"
import {Typography} from 'antd';

const {Title, Paragraph} = Typography;

const MaintenancePage = () => {

    return (
        <div
            style={{
                top: 0,
                display: 'flex',
                position: "absolute",
                height: "100%",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <img src={MaintenanceImage} style={{width: '40%', backgroundSize: 'cover', marginBottom: 20}}/>
            <Title level={4} style={{marginBottom: 20, marginTop: 50}}>Website under maintenance...</Title>
            <Paragraph style={{textAlign: "center"}}>Our website is currently undergoing scheduled
                maintenance</Paragraph>
            <Paragraph style={{textAlign: "center"}}>We should be back shortly. Thank you for your patience</Paragraph>
        </div>
    )
}

export default MaintenancePage
