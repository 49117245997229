import React, {useEffect, useState} from "react";
import {Button, Modal, Row, Table, Typography} from "antd";
import CreateLinkComponent from "./create-link-form.component";
import {deleteEmbedUrlApi, getEmbedUrlListApi} from "../../api/embed-url";
import {MENU_REDUCER} from "../../reducers/menu.reducer";
import {useDispatch, useSelector} from "react-redux";
import {MODAl_REDUCER} from "../../reducers/modal.reducer";
import {getMenuListApi} from "../../api/menu.api";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";
import {FLUSH_SEARCH_KEY} from "../../reducers/analytics.reducer";

const {Text} = Typography;

const LinkSetting = () => {
    const dispatch = useDispatch();
    const [editModalVisibility, setEditModalVisibility] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [linkDetail, setLinkDetail] = useState({})
    const {subSubMenuReload, embedUrlList} = useSelector(state => state.menuReducer)
    const {searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            title: 'Name', dataIndex: 'name', key: 'name',
            render: name => <div style={Styles.fontArmyGrayBold}>{name}</div>
        },
        {
            title: 'Sub Menu', dataIndex: 'menu', key: 'menu', width: 200,
            render: (menu) => <div style={{color: Colors.armyGray}}>{menu.name}</div>
        },
        {
            title: 'Slug', dataIndex: 'slug', key: 'slug', width: 200,
            render: (slug) => <div style={{color: Colors.darkGrey}}>{slug}</div>
        },
        {
            title: 'Url', dataIndex: 'url', key: 'url', width: 500,
            render: (url) => <Text
                style={{wordWrap: 'break-word', wordBreak: 'break-word', color: Colors.darkGrey}}>{url}</Text>
        },
        {
            title: 'Action', key: 'edit', width: 250,
            render: (record) => {
                return (
                    <>
                        <Button onClick={() => {
                            setEditModalVisibility(true)
                            setModalTitle('Update sub sub menu')
                            setLinkDetail(record)
                        }} style={Styles.buttonUpdateDelete}>
                            <div style={Styles.fontArmyGrayBold}>Update</div>
                        </Button>
                        <Button onClick={() => {
                            setEditModalVisibility(true)
                            setModalTitle('Delete sub sub menu')
                            setLinkDetail(record)
                        }} style={{borderRadius: 12}}>
                            <div style={Styles.fontArmyGrayBold}>Delete</div>
                        </Button>
                    </>
                )
            }
        }
    ])

    useEffect(() => {
        Promise.all([getEmbedUrlListApi(), getMenuListApi()])
            .then(response => {
                dispatch({
                    type: MENU_REDUCER,
                    payload: {
                        menuLoading: false,
                        embedUrlList: response[0],
                        menuList: response[1]

                    }
                })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({type: MENU_REDUCER, payload: {menuLoading: false}})
            })

        return () => {
            dispatch({type: FLUSH_SEARCH_KEY})
        }
    }, [subSubMenuReload])

    const deleteSubSubMenu = () => {
        deleteEmbedUrlApi(linkDetail?.id)
            .then(response => {
                setEditModalVisibility(false)
                dispatch({type: MENU_REDUCER, payload: {subSubMenuReload: !subSubMenuReload}})
                alert('Sub sub menu berhasil dihapus!')
            })
            .catch(err => console.log(err.response))
    }

    const filteredData = () => {
        let dataFiltered = []
        embedUrlList.forEach(item => {
            if(item.name.toLowerCase().includes(searchKey.toLowerCase())){
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }

    return (
        <div>
            <Row justify={"space-between"} style={{marginTop: 20}}>
                <Button style={Styles.button} onClick={() => {
                    setEditModalVisibility(true)
                    setModalTitle('Create Sub Sub Menu')
                    setLinkDetail({})
                }}>Create Sub Sub Menu</Button>
                <SearchComponent/>
            </Row>
            <Table
                columnWidth={100}
                size="small"
                className="components-table-demo-nested"
                columns={columns}
                dataSource={filteredData()}
                rowKey={'id'}
            />
            <Modal
                visible={editModalVisibility}
                title={<div style={Styles.fontDarkGreenBold}>{modalTitle}</div>}
                onCancel={() => {
                    setLinkDetail({})
                    setEditModalVisibility(false)
                }}
                footer={
                    modalTitle.includes('Delete') ?
                        <div>
                            <Button onClick={() => deleteSubSubMenu()} style={Styles.buttonOk}>Ya</Button>
                            <Button onClick={() => {
                                setEditModalVisibility(false)
                            }} style={Styles.buttonCancel}>Tidak</Button>
                        </div> : null
                }
            >
                {
                    modalTitle.includes('Delete') ?
                        <div style={{color: Colors.armyGray}}>Apakah anda yakin ingin menghapus sub sub menu
                            ini?</div> :
                        <CreateLinkComponent
                            onCancelModal={() => {
                                setLinkDetail({})
                                setEditModalVisibility(false)
                            }}
                            modalTitle={modalTitle}
                            linkDetail={linkDetail}
                        />}
            </Modal>
        </div>
    )
}

export default LinkSetting
