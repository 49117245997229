import React, {useEffect, useState} from "react";
import {deleteUserDetailApi, getUserListApi} from "../../api/auth.api";
import {Button, Modal, Row, Table} from "antd";
import CreateUserFormComponent from "./create-user-form.component";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import SearchComponent from "../../components/search.component";
import {useDispatch, useSelector} from "react-redux";
import {FLUSH_SEARCH_KEY} from "../../reducers/analytics.reducer";

const UserSettingPage = () => {
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([])
    const [modalVisibility, setModalVisibility] = useState(false)
    const [modalState, setModalState] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [userDetail, setUserDetail] = useState({})
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
    const {searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            title: 'Name', dataIndex: 'name', key: 'name',
            render: name => <div style={Styles.fontArmyGrayBold}>{name}</div>
        },
        {
            title: 'Email', dataIndex: 'email', key: 'email',
            render: (email) => <div style={{color: Colors.armyGray}}>{email}</div>
        },
        {
            title: 'Role', dataIndex: 'role', key: 'role',
            render: (role) => <div style={{color: Colors.darkGrey}}>{role.name}</div>
        },
        {
            title: 'Action', key: 'edit',
            render: (record) => {
                return (
                    <>
                        <Button onClick={() => {
                            setModalVisibility(true)
                            setModalState('update')
                            setModalTitle('Update User')
                            setUserDetail(record)
                        }} style={Styles.buttonUpdateDelete}>
                            <div style={Styles.fontArmyGrayBold}>Update</div>
                        </Button>
                        <Button onClick={() => {
                            setDeleteModalVisibility(true)
                            setUserDetail(record)
                        }} style={{borderRadius: 12}}>
                            <div style={Styles.fontArmyGrayBold}>Delete</div>
                        </Button>
                    </>
                )
            }
        }
    ])

    useEffect(() => {
        const getUserList = async () => {
            try {
                const result = await getUserListApi()
                setUserList(result)
            } catch (e) {
                console.log('Error getUserListApi', e)
            }
        }

        getUserList()

        return () => {
            dispatch({type: FLUSH_SEARCH_KEY})
        }
    }, [])

    const deleteUser = async () => {
        deleteUserDetailApi(userDetail.id)
            .then(response => {
                alert('User berhasil dihapus!!')
                setDeleteModalVisibility(false)
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    const filteredData = () => {
        let dataFiltered = []
        userList.forEach(item => {
            if(item.name.toLowerCase().includes(searchKey.toLowerCase())){
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }

    return (
        <>
            <Row justify={"space-between"} style={{marginTop: 20}}>
                <Button style={Styles.button} onClick={() => {
                    setModalVisibility(true)
                    setModalState('create')
                    setModalTitle('Create User')
                }}>Create User</Button>
                <SearchComponent />
            </Row>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={filteredData()}
                rowKey={'id'}
            />
            <Modal
                visible={modalVisibility}
                title={<div style={Styles.fontDarkGreenBold}>{modalTitle}</div>}
                onCancel={() => {
                    setModalVisibility(false)
                    setUserDetail({})
                }}
                footer={null}
            >
                <CreateUserFormComponent action={modalState} userDetail={userDetail}/>
            </Modal>
            <Modal
                visible={deleteModalVisibility}
                title={<div style={Styles.fontDarkGreenBold}>Delete user</div>}
                onCancel={() => {
                    setDeleteModalVisibility(false)
                }}
                footer={[
                    <Button style={Styles.buttonCancel} key="back" onClick={() => setDeleteModalVisibility(false)}>
                        Cancel
                    </Button>,
                    <Button style={Styles.buttonOk} key="submit" type="primary" onClick={() => deleteUser()}>
                        Ok
                    </Button>
                ]}
            >
                <p style={{color: Colors.armyGray}}>Apakah anda yakin ingin menghapus pengguna ini?</p>
            </Modal>
        </>
    )
}

export default UserSettingPage
