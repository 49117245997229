import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export default function RouteWrapper(
    {
        component: Component,
        isPrivate,
        ...rest
    }) {

    const signed = localStorage.getItem('TOKEN_AUTH');
    const role64 = localStorage.getItem('ROLE');

    /**
     * Redirect user to signIn pages if he tries to access a private      route
     * without emailAuth.
     */
    if (isPrivate && !signed) {
        return <Redirect to="/"/>;
    }

    /**
     * Redirect user to Main pages if he tries to access a non private route
     * (signIn or SignUp) after being authenticated.
     */
    if (!isPrivate && !!signed) {
        return <Redirect to="/"/>;
    }

    if (!isPrivate && !!signed && !!role64) {
        localStorage.removeItem('TOKEN_AUTH')
        localStorage.removeItem('ROLE')
        return <Redirect to="/"/>;
    }

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return <Route {...rest} component={Component}/>;
}
