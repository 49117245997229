import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getAnalyticPageApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/analytic/page/access`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response);
        });
    }));
}

const getAnalyticUserApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/analytic/user/access`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err.response);
        });
    }));
}

export {
    getAnalyticPageApi,
    getAnalyticUserApi
}
