import React, {useEffect, useState} from "react";
import {Row, Table} from "antd";
import {Styles} from "../../ui-util/styles";
import {Colors} from "../../ui-util/colors";
import {getAnalyticUserApi} from "../../api/analytics.api";
import DatePickerComponent from "../../components/date-picker.component";
import {useDispatch, useSelector} from "react-redux";
import {FLUSH_DATE_ANALYTICS} from "../../reducers/analytics.reducer";
import SearchComponent from "../../components/search.component";
import {formatDateToReadable} from "../../ui-util/general-variable";
import ExportAnalyticUserComponent from "../../components/export-analytic-user.component";

const AnalyticUserPage = () => {
    const [userList, setUserList] = useState([])
    const {reload, startDate, endDate, searchKey} = useSelector(state => state.analyticsReducer)
    const [columns] = useState([
        {
            title: 'Name', dataIndex: 'name', key: 'name',
            render: name => <div style={Styles.fontArmyGrayBold}>{name}</div>
        }, {
            title: 'Email', dataIndex: 'email', key: 'email',
            render: (email) => <div style={{color: Colors.armyGray}}>{email}</div>
        }, {
            title: 'Page Access Count', dataIndex: 'logs', key: 'logs',
            render: (logs) => <div style={{color: Colors.darkGrey}}>{logs.length} Pages</div>
        }
    ])

    useEffect(() => {

        getAnalyticsData()

    }, [startDate, endDate])

    const getAnalyticsData = () => {
        let requestBody = {
            startDate,
            endDate
        }

        getAnalyticUserApi(requestBody)
            .then(result => {
                setUserList(result)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const filteredData = () => {
        let dataFiltered = []
        userList.forEach(item => {
            if (item.name.toLowerCase().includes(searchKey.toLowerCase())) {
                dataFiltered.push(item)
            }
        })

        return dataFiltered
    }

    const renderPageName = (data) => {
        return (
            <Table
                style={{marginLeft: 65}}
                columnWidth={100}
                className="components-table-demo-nested"
                columns={[
                    {
                        title: 'Page', dataIndex: 'page', key: 'page',
                        render: page => <div style={{color: Colors.darkGrey}}>{page}</div>
                    }, {
                        title: 'Tanggal Akses', dataIndex: 'created_at', key: 'created_at',
                        render: created_at => <div style={{color: Colors.darkGrey}}>{formatDateToReadable(created_at, 'DD-MM-YYYY')}</div>
                    },
                ]}
                dataSource={data}
                rowKey={'id'}
            />
        )
    }

    const dataFormatExcel = () => {
        let data = []
        filteredData().forEach(item => {
            item.logs.forEach((log, index) => {
                let dataLog = {}
                if(index === 0){
                    dataLog.name = item.name
                    dataLog.email = item.email
                    dataLog.totalPage = item.logs.length
                    dataLog.page = log.page
                    dataLog.accessTime = formatDateToReadable(log.created_at, 'DD-MM-YYYY')
                } else {
                    dataLog.page = log.page
                    dataLog.accessTime = formatDateToReadable(log.created_at, 'DD-MM-YYYY')
                }
                data.push(dataLog)
            })
        })
        return data
    }

    return (
        <div>
            <Row style={{marginBottom: 20}} justify={"space-between"}>
                <SearchComponent />
                <ExportAnalyticUserComponent dataList={dataFormatExcel()}/>
                <DatePickerComponent />
            </Row>
            <Table
                columnWidth={100}
                className="components-table-demo-nested"
                columns={columns}
                dataSource={filteredData()}
                rowKey={'id'}
                expandedRowRender={(record) => renderPageName(record.logs)}
            />
        </div>
    )
}

export default AnalyticUserPage
