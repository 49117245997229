import React, {useEffect, useState} from "react";
import {DatePicker} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {ANALYTICS_REDUCER, FLUSH_DATE_ANALYTICS} from "../reducers/analytics.reducer";
import moment from "moment";

const {RangePicker} = DatePicker;

const DatePickerComponent = () => {
    const dispatch = useDispatch();
    const {startDate, endDate} = useSelector(state => state.analyticsReducer)
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())

    useEffect(() => {

        setStart(startDate)
        setEnd(endDate)

    }, [startDate, endDate])

    useEffect(() => {
        return () => {
            dispatch({type: FLUSH_DATE_ANALYTICS})
        }
    }, [])

    const onChangeDatePicker = (date) => {
        dispatch({type: ANALYTICS_REDUCER, payload: {startDate: date[0], endDate: date[1]}})
    }

    return (
        <div>
            <RangePicker
                defaultValue={[start, end]}
                format={'DD/MM/YYYY'}
                onChange={date => onChangeDatePicker(date)}
            />
        </div>
    )
}

export default DatePickerComponent
