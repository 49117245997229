import React from "react";
import './App.css';
import {BrowserRouter} from "react-router-dom";
import Dashboard from "./components/dashboard.component";
import Routes from "./routes";

import {Provider} from "react-redux";
import {applyMiddleware, createStore} from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk';

const store = createStore(rootReducer, {}, applyMiddleware(thunk))

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Dashboard content={<Routes/>}/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
